<template>
	<div class="card">
		<div class="card-body">
			<div class="row">
				<div class="col-md-12">
					<clients />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import clients from '@/views/clients'
import utilities from '@/services/utilities'

export default {
	name: 'ClientesView',
	components: {
		clients,
	},
	computed: {
		...mapState(['settings']),
		actualSubmenus() {
			if (utilities.objectValidate(this.settings, 'menu', false)) {
				let index = this.settings.menu.findIndex((e) => e.url == this.$route.path)
				this.onChangeTab(this.settings.menu[index].submenus[0].key)
				return this.settings.menu[index].submenus
			} else {
				return []
			}
		},
	},
	data() {
		return {
			activeTab: '',
		}
	},
	methods: {
		onChangeTab(key) {
			this.activeTab = key
		},
	},
}
</script>